import { ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import { max, range } from 'lodash'

type HeatMapColorRange = Array<{
  from?: number
  to?: number
  color?: string
  foreColor?: string
  name?: string
}>

const colors: Record<number, string> = {
  0: '#e6ecff',
  1: '#ccd9ff',
  2: '#b3c6ff',
  3: '#84a2fa',
  4: '#5882ff',
  5: '#3c6eff',
  6: '#1a57ff',
  7: '#0040ff',
}

const heatMapColorRangeGenerator = (series: ApexChartSeries):HeatMapColorRange => {
  const flatSeriesData = series.map(s => s.data).flat()
  const maxSeriesData = max(flatSeriesData)

  const ranges: HeatMapColorRange = [ {
    from: 0,
    to: 0,
    color: '#ffffff',
  } ]

  if (maxSeriesData) {
    return ranges.concat(
      range(0, maxSeriesData, maxSeriesData / 7)
          .map((step, index, arr) => {
            return {
              from: step,
              to: arr[index + 1] ?? maxSeriesData,
              color: colors[index],
            }
          })
      )
  }

  return ranges
}

export default heatMapColorRangeGenerator
