















import { ApexLocales } from "@/views/chat/statistics/logic/defaultApexSettings";
import { ChartParams } from "@/views/chat/statistics/logic/types";
import { UsersRawData, User } from "@/views/chat/statistics/logic/usersStatTypes";

import { UseFields } from "piramis-base-components/src/components/Pi";

import { Component, Mixins, Prop } from 'vue-property-decorator'
import VueApexCharts from "vue-apexcharts";

@Component({
  components: {
    VueApexCharts
  }
})
export default class TopActiveUsers extends Mixins(UseFields) {
  @Prop() rawData!: UsersRawData

  users: ChartParams | null = null

  mounted(): void {
    let users = this.rawData.users
      .filter((user) => user?.message_count !== undefined)
      .slice()
      .sort((a, b) => (b?.message_count ?? 0) - (a?.message_count ?? 0))

    let top: Array<User> = []

    let h = 0
    while (h < 10) {
      const user = users.shift()
      if (user) {
        top.push(user)
      }

      h++
    }

    let data: Array<{ name: string | number, data: number }> = top.map(user => {
      return {
        name: user.user_name ?? user.user_id,
        data: user?.message_count ?? 0
      }
    })

    this.users = {
      series: [
        {
          data: data.map(item => item.data),
        }
      ],
      options: this.getOptions(data.map(item => item.name), Math.max.apply(null, data.map((item) => item.data)))
    }
  }

  getOptions(labels: Array<string | number>, max: number): any {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 4,
        }
      },
      legend: {
        position: 'bottom',
        formatter: (label: string, obj: any) => {
          const value = obj.w.config.series[0].data[obj.seriesIndex]
          return `${ label }: ${ value }`
        }
      },
      labels: labels,
      responsive: [
        {
          breakpoint: 991,
          options: {
            chart: {
              toolbar: {
                show: true,
                tools: {
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true,
                  download: false
                }
              },
              height: 300
            }
          }
        }
      ],
      dataLabels: {
        enabled: false
      },
      yaxis: {
        min: 0,
        max: (Math.round((max * 1.2) / 10)) * 10,
      },
      xaxis: {
        min: 0,
        max: 100,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          formatter: () => {
            return ''
          }
        }
      },
      grid: {
        show: true
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
          formatter: function (val: string) {
            return val;
          },
        },
      },
    }
  }
}
