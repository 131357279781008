import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import { ApexOptions } from "apexcharts";

//todo
export type RecordParams = {
  icon: string
  color: string
  value: number
  title: string
}

export type Statistics = {
  engagement_rate_month: Array<EngagementRate>
  engagement_rate_week: Array<EngagementRate>
  messages_retention: Array<MessagesRetention>
  msg_count_day: Array<MsgCountDay>
  msg_count_hour: Array<MsgCountDay>
  msg_count_month: Array<MsgCountDay>
  msg_count_week: Array<MsgCountDay>
  users_new_day: Array<User>
  users_new_hour: Array<User>
  users_retention: Array<UsersRetention>
  users_tick: Array<UsersTick>
}

export type UsersTick = {
  kick: number
  leave: number
  new: number
  online: number
  time: string
  total?: number
}

export type UsersRetention = {
  msg_count: number
  msg_edited_count: number
  msg_reply_count: number
  time: string
  user_days_from_first_message: Array<number>
  user_days_from_first_message_more: number
  user_days_in_chat: Array<number>
  user_days_in_chat_more: number
  users_count: number
}

export type MessagesRetention = {
  msg_count: number
  msg_days_from_first_message: Array<number>
  msg_days_from_first_message_more: number
  msg_days_in_chat: Array<number>
  msg_days_in_chat_more: number
  msg_edited_count: number
  msg_reply_count: number
  time: string
  users_count: string
}

export type User = {
  first_users: number
  repeated_users: number
  time: string
  total_invited_users: number
  total_users: number
  unique_first_users: number
  unique_invited_users: number
  unique_repeated_users: number
  unique_leave_users: number
  unique_kicked_users: number
}

export type MsgCountDay = {
  msg_count: number
  msg_count_animation: number
  msg_count_audio: number
  msg_count_contact: number
  msg_count_document: number
  msg_count_game: number
  msg_count_invoice: number
  msg_count_location: number
  msg_count_passportdata: number
  msg_count_photo: number
  msg_count_poll: number
  msg_count_sticker: number
  msg_count_text: number
  msg_count_venue: number
  msg_count_video: number
  msg_count_videonote: number
  msg_count_voice: number
  msg_edited_count: number
  msg_edited_count_animation: number
  msg_edited_count_audio: number
  msg_edited_count_contact: number
  msg_edited_count_document: number
  msg_edited_count_game: number
  msg_edited_count_invoice: number
  msg_edited_count_location: number
  msg_edited_count_passportdata: number
  msg_edited_count_photo: number
  msg_edited_count_poll: number
  msg_edited_count_sticker: number
  msg_edited_count_text: number
  msg_edited_count_venue: number
  msg_edited_count_video: number
  msg_edited_count_videonote: number
  msg_edited_count_voice: number
  msg_reply_count: number
  msg_reply_count_animation: number
  msg_reply_count_audio: number
  msg_reply_count_contact: number
  msg_reply_count_document: number
  msg_reply_count_game: number
  msg_reply_count_invoice: number
  msg_reply_count_location: number
  msg_reply_count_passportdata: number
  msg_reply_count_photo: number
  msg_reply_count_poll: number
  msg_reply_count_sticker: number
  msg_reply_count_text: number
  msg_reply_count_venue: number
  msg_reply_count_video: number
  msg_reply_count_videonote: number
  msg_reply_count_voice: number
  time: string
  users_count: number
  users_count_animation: number
  users_count_audio: number
  users_count_contact: number
  users_count_document: number
  users_count_game: number
  users_count_invoice: number
  users_count_location: number
  users_count_passportdata: number
  users_count_photo: number
  users_count_poll: number
  users_count_sticker: number
  users_count_text: number
  users_count_venue: number
  users_count_video: number
  users_count_videonote: number
  users_count_voice: number
}

export type EngagementRate = {
  engagement_rate_users?: number
  msg_count: number
  time: string
  users_count: Array<number>
}

export type ChartParams = {
  series: Array<{
    name?: string
    type?: string
    data: Array<number> | number
  }>
  options: any
}

export type RatioParams = {
  series: Array<{
    name: string
    color: string
    data: number
  }>
}

export type ChartInfo = { series: ApexChartSeries, options: ApexOptions }

export enum StatisticsPeriodKey {
  Day,
  Hour,
  Week,
  Month
}
